<template>
  <div class="login-card">
    <div class="row">
      <div class="col-md-6 d-md-block d-none">
        <img class="w-100" src="../../assets/img/auth/login-backgroun.png" alt="">
        <div class="etmaam">
          <img src="../../assets/img/global/Logo.webp" alt="Etmaam Logo">
          <p>
            {{
              this.$i18n.locale == 'ar' ?
                `استفد من خبرتنا واستمتع بخدمات متميزة تلبي احتياجاتك بدقة واحترافية.`
                :
                `Benefit from our experience and enjoy distinguished services that meet your needs accurately and
            professionally.`

            }}
          </p>
        </div>
      </div>
      <div class="col-md-6">
        <Form class="auth-form" @submit="HandelLogin" :validation-schema="schema">
          <div class="row">
            <div class="col-12">
              <h1 class="title">{{ $t('Login') }}</h1>
            </div>
            <div class="col-12 field">
              <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M1.77042 3.87129C1.48293 3.70649 1.11628 3.80596 0.951487 4.09345C0.786694 4.38094 0.88616 4.74759 1.17365 4.91238L1.77042 3.87129ZM7.36455 7.7695L7.666 7.25071L7.66294 7.24896L7.36455 7.7695ZM10.6155 7.7695L10.3177 7.24859L10.314 7.25072L10.6155 7.7695ZM16.8221 4.91273C17.1098 4.74829 17.2097 4.38176 17.0452 4.09407C16.8808 3.80639 16.5143 3.70648 16.2266 3.87093L16.8221 4.91273ZM4.70495 1.64688H13.2922V0.446875H4.70495V1.64688ZM13.2922 1.64688C14.7676 1.64688 15.9636 2.84288 15.9636 4.31823H17.1636C17.1636 2.18014 15.4303 0.446875 13.2922 0.446875V1.64688ZM15.9636 4.31823V11.6788H17.1636V4.31823H15.9636ZM15.9636 11.6788C15.9636 13.1541 14.7676 14.3501 13.2922 14.3501V15.5501C15.4303 15.5501 17.1636 13.8169 17.1636 11.6788H15.9636ZM13.2922 14.3501H4.70495V15.5501H13.2922V14.3501ZM4.70495 14.3501C3.2296 14.3501 2.03359 13.1541 2.03359 11.6788H0.833594C0.833594 13.8169 2.56686 15.5501 4.70495 15.5501V14.3501ZM2.03359 11.6788V4.31823H0.833594V11.6788H2.03359ZM2.03359 4.31823C2.03359 2.84288 3.2296 1.64688 4.70495 1.64688V0.446875C2.56686 0.446875 0.833594 2.18014 0.833594 4.31823H2.03359ZM1.17365 4.91238L7.06617 8.29005L7.66294 7.24896L1.77042 3.87129L1.17365 4.91238ZM7.06311 8.28828C7.64835 8.62834 8.31315 8.80746 8.99001 8.80746V7.60746C8.52492 7.60746 8.06812 7.48438 7.666 7.25072L7.06311 8.28828ZM8.99001 8.80746C9.66686 8.80746 10.3317 8.62834 10.9169 8.28828L10.314 7.25072C9.91189 7.48438 9.45509 7.60746 8.99001 7.60746V8.80746ZM10.9132 8.29041L16.8221 4.91273L16.2266 3.87093L10.3177 7.2486L10.9132 8.29041Z"
                  fill="#0a3041" />
              </svg>
              <!-- <input v-model="formData.phone" @input="handelPhoneError"
        required
        type="phone" 
        name="phone" 
        id="phone" 
        :placeholder="$t('PhoneNumber')"> -->
              <Field name="email" type="email" :placeholder="$t('Email')" />
              <ErrorMessage name="email" class="error-message" />
            </div>
            <div class="col-12 field" style="position: relative">
              <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M2.375 7.71428C2.20924 7.71428 2.05027 7.78201 1.93306 7.90257C1.81585 8.02313 1.75 8.18664 1.75 8.35714V16.0714C1.75 16.2419 1.81585 16.4054 1.93306 16.526C2.05027 16.6466 2.20924 16.7143 2.375 16.7143H13.625C13.7908 16.7143 13.9497 16.6466 14.0669 16.526C14.1842 16.4054 14.25 16.2419 14.25 16.0714V8.35714C14.25 8.18664 14.1842 8.02313 14.0669 7.90257C13.9497 7.78201 13.7908 7.71428 13.625 7.71428H2.375ZM2.375 6.42857H13.625C14.1223 6.42857 14.5992 6.63176 14.9508 6.99344C15.3025 7.35511 15.5 7.84565 15.5 8.35714V16.0714C15.5 16.5829 15.3025 17.0735 14.9508 17.4351C14.5992 17.7968 14.1223 18 13.625 18H2.375C1.87772 18 1.40081 17.7968 1.04917 17.4351C0.697544 17.0735 0.5 16.5829 0.5 16.0714V8.35714C0.5 7.84565 0.697544 7.35511 1.04917 6.99344C1.40081 6.63176 1.87772 6.42857 2.375 6.42857Z"
                  fill="#0a3041" />
                <path
                  d="M8 9.64285C8.16576 9.64285 8.32473 9.71058 8.44194 9.83114C8.55915 9.9517 8.625 10.1152 8.625 10.2857V14.1429C8.625 14.3134 8.55915 14.4769 8.44194 14.5974C8.32473 14.718 8.16576 14.7857 8 14.7857C7.83424 14.7857 7.67527 14.718 7.55806 14.5974C7.44085 14.4769 7.375 14.3134 7.375 14.1429V10.2857C7.375 10.1152 7.44085 9.9517 7.55806 9.83114C7.67527 9.71058 7.83424 9.64285 8 9.64285ZM11.75 6.42857V5.14286C11.75 4.11988 11.3549 3.1388 10.6517 2.41544C9.94839 1.69209 8.99456 1.28571 8 1.28571C7.00544 1.28571 6.05161 1.69209 5.34835 2.41544C4.64509 3.1388 4.25 4.11988 4.25 5.14286V6.42857H11.75ZM8 0C9.32608 0 10.5979 0.541835 11.5355 1.50631C12.4732 2.47078 13 3.77889 13 5.14286V7.71428H3V5.14286C3 3.77889 3.52678 2.47078 4.46447 1.50631C5.40215 0.541835 6.67392 0 8 0Z"
                  fill="#0a3041" />
              </svg>
              <Field name="password" :type="type_password" id="password" :placeholder="$t('Password')" />
              <ErrorMessage name="password" class="error-message" />
              <svg @click="showPassword" style="position: absolute;right:auto;
                    left: 26px;
                    top: 16px;
                    cursor: pointer;" width="16" height="11" viewBox="0 0 16 11" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M8 0.0458984C4.36364 0.0458984 1.25818 2.30772 0 5.50044C1.25818 8.69317 4.36364 10.955 8 10.955C11.6364 10.955 14.7418 8.69317 16 5.50044C14.7418 2.30772 11.6364 0.0458984 8 0.0458984ZM8 9.13681C5.99273 9.13681 4.36364 7.50772 4.36364 5.50044C4.36364 3.49317 5.99273 1.86408 8 1.86408C10.0073 1.86408 11.6364 3.49317 11.6364 5.50044C11.6364 7.50772 10.0073 9.13681 8 9.13681ZM8 3.31863C6.79273 3.31863 5.81818 4.29317 5.81818 5.50044C5.81818 6.70772 6.79273 7.68226 8 7.68226C9.20727 7.68226 10.1818 6.70772 10.1818 5.50044C10.1818 4.29317 9.20727 3.31863 8 3.31863Z"
                  fill="#225476" />
              </svg>
            </div>
            <div class="col-12 mt-1">
              <router-link to="/forgot" class="forgot">
                {{ $t('Forgot_Password') }}
              </router-link>
            </div>
            <div class="col-12">
              <button class="btn-main">{{ $t('Login') }}</button>
            </div>
            <div class="col-12 d-flex align-items-center justify-content-between ">
              <span>
                {{ this.$i18n.locale == 'ar' ? `ليس لديك حساب بعد ؟` : `Don't have an account yet ?` }}
              </span>
              <router-link to="/register" class="btn-third">
                {{ $t('Register') }}
              </router-link>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { notify } from "@kyvg/vue3-notification";
// import vee-validate 
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import { configure } from "vee-validate";
import Cookie from 'cookie-universal'
const cookie = Cookie()
export default {
  data() {
    return {
      type_password: 'password',
      ShowVerification: true,
    }
  },
  setup() {
    configure({
      validateOnInput: true,
    });
    const schema = yup.object({
      email: yup.string().required(cookie.get('lang') == 'ar' ? 'حقل البريد الالكتروني مطلوب' : 'email is required').email(cookie.get('lang') == 'ar' ? 'يجب ان يكون البريد الالكتروني محقق' : 'email must be valid'),
      password: yup.string().required(cookie.get('lang') == 'ar' ? 'كلمة المرور  مطلوبة' : 'password is required').min(8, cookie.get('lang') == 'ar' ? 'يجب أن تتكون كلمة المرور من 8 أحرف على الأقل' : 'password must be at least 8 characters'),
    });

    return {
      schema,
    }
  },
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  methods: {
    showPassword() {
      if (this.type_password == 'text') {
        this.type_password = 'password'
      }
      else {
        this.type_password = 'text';
      }
    },
    async HandelLogin(values) {
      var self = this;
      const formData = {
        "email": values.email,
        "password": values.password,

      }
      await axios.post(`/user/auth/login`, formData)
        .then((res) => {
            console.log(res.status);
          if (res.status == 200) {
            if (this.$route.query.AcceptInvite != undefined) {
              axios.get(`/user/invitations/accept-invitation/${this.$route.query.AcceptInvite}`, {
                headers: { 'Authorization': `Bearer ${cookie.get('Token')}` }
              })
                .then((res) => {
                  notify({
                    type: "success",
                    text: this.$i18n.locale == 'ar' ? "تم قبول الدعوة بنجاح" : 'accept invitation Success',
                  });
                  setTimeout(() => {
                    this.$router.push('/services');
                  }, 2000);
                })
                .catch(function (error) {
                  if (error) {
                    notify({
                      type: "error",
                      title: "خـطـأ !!",
                      text: error?.response?.data?.message,
                    });
                  }
                });
            }
            else {
              if (res.data.data.company == null) {
                this.$router.push('/services');
              }
              else {
                this.$router.push('/profile/home');
              }
            }

            notify({
              type: "success",
              title: "تسجيل الدخول",
              text: this.$i18n.locale == 'ar' ? "تم تسجيل الدخول بنجاح" : 'LogIn Success',
            });
            this.$store.commit("SET_Token", res.data.data);
          }
         
        })
        .catch(function (error) {
          console.log(error.response.status)
          if(error.response.status == 403){
            notify({
              type: "error",
              text: error?.response?.data?.message,
            });
            self.$router.push('/verification');
          }
          else {
            notify({
              type: "error",
              text: error?.response?.data?.message,
            });
          }
        });
    }
  },


}
</script>